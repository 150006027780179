const strategyList = [
  {
    id: "2eb23e3b-92b3-4a52-9e9f-830d9942c0e7",
    name: "LLT-低延迟均线",
    intro: "LLT低延迟均线",
    paper: [
      {
        title: "广发证券-低延迟趋势线与交易性择时-短线择时策略研究之三.pdf",
        url: "https://echoquants-1251358008.cos.ap-chengdu.myqcloud.com/20170303-%E5%B9%BF%E5%8F%91%E8%AF%81%E5%88%B8-%E4%BD%8E%E5%BB%B6%E8%BF%9F%E8%B6%8B%E5%8A%BF%E7%BA%BF%E4%B8%8E%E4%BA%A4%E6%98%93%E6%8B%A9%E6%97%B6.pdf",
      },
    ],
    onlyFirm: false,
  },
  {
    id: "82318ff9-81b8-466c-9674-afcd8dad5933",
    name: "gridtrade-网格交易",
    intro: "网格交易",
  },
  {
    id: "d4535b8e-b305-48d5-93c6-17bff773e9c8",
    name: "support_resistance-支撑阻力策略",
    intro: "支撑阻力策略",
    paper: [
      {
        title: "151 Trading Strategies(策略参考3.14章).pdf",
        url: "https://echoquants-1251358008.cos.ap-chengdu.myqcloud.com/151%20Trading%20Strategies.pdf",
      },
    ],
    onlyFirm: false,
  },
  {
    id: "df7ed4af-7376-44da-a70a-c37c65c90687",
    name: "QRS-支撑阻力策略",
    intro: `RSRS指标的全称是“阻力支撑相对强度（Resistance Support Relative Strength）”，它诞生于光大证券在2017年劳动节发布的金工研报《基于阻力支撑相对强度的市场择时》。<br>
阻力位和支撑位的策略主要应用在价格突破和反转。定位有效的阻力位和支撑位是策略成功的关键。有各种方法定义阻力和支撑位，如布林带的上下轨、前高与前低、区间震荡线、趋势线和M头、W底等。均线策略也是利用这两者：价格在均线下方，均线是阻力位；上方，则是支撑位。<br>
<u>市场中，大多数交易者将这两个点位当作固定值使用。以布林带和均线策略为例，虽然它们在趋势中有效，但在震荡市场中会频繁亏损。考虑交易成本，均线策略并不总是理想的。</u><br>
另一个视角是考虑阻力位和支撑位的相对强度，而非作为固定阈值。它们反映了市场对当前状态的预期。如果预期容易改变，那么其有效性弱。相反，预期强烈一致时，其有效性强。<br>
支撑和阻力的相对强度可以用来判断市场的可能转向。例如，明显的支撑超过阻力，可能表示牛市将继续，而阻力超过支撑则可能表示牛市即将结束。<br>
为了量化定义这两者，我们考虑使用每日最高价和最低价，因为它们反映了市场对阻力和支撑的认识。通过最近N个数据点来描述其相对变化程度，使用线性回归的斜率代表相对强度。当斜率较大时，支撑强于阻力。<br>
基于上述方法，我们建立了RSRS指标，考虑直接利用斜率或其标准化值作为指标。计算当日斜率时，先取前N日的最高价和最低价，然后进行线性回归，用beta值作为RSRS斜率指标。而计算标准分时，首先取前M日的斜率，然后计算当日斜率的标准分，作为RSRS标准分指标值。<br>
简而言之，理解和有效应用阻力位和支撑位是成功交易的关键。除了传统方法，考虑它们的相对强度也是一个有趣和可能更为精确的方法。<br>`,
    paper: [
      {
        title: "光大证券-基于阻力支撑相对强度（RSRS）的市场择时.pdf",
        url: "https://echoquants-1251358008.cos.ap-chengdu.myqcloud.com/%E5%85%89%E5%A4%A7%E8%AF%81%E5%88%B8-%E6%8B%A9%E6%97%B6%E7%B3%BB%E5%88%97%E6%8A%A5%E5%91%8A%E4%B9%8B%E4%B8%80%EF%BC%9A%E5%9F%BA%E4%BA%8E%E9%98%BB%E5%8A%9B%E6%94%AF%E6%92%91%E7%9B%B8%E5%AF%B9%E5%BC%BA%E5%BA%A6%EF%BC%88RSRS%EF%BC%89%E7%9A%84%E5%B8%82%E5%9C%BA%E6%8B%A9%E6%97%B6.pdf",
      },
      {
        title: "光大证券-RSRS择时~回顾与改进.pdf",
        url: "https://echoquants-1251358008.cos.ap-chengdu.myqcloud.com/%E5%85%89%E5%A4%A7%E8%AF%81%E5%88%B8-%E6%8A%80%E6%9C%AF%E6%8C%87%E6%A0%87%E7%B3%BB%E5%88%97%E6%8A%A5%E5%91%8A%E4%B9%8B%E5%85%AD%EF%BC%9ARSRS%E6%8B%A9%E6%97%B6~%E5%9B%9E%E9%A1%BE%E4%B8%8E%E6%94%B9%E8%BF%9B.pdf",
      },
      {
        title: "金融工程视角下的技术择时艺术-刘均伟.pdf",
        url: "https://echoquants-1251358008.cos.ap-chengdu.myqcloud.com/%E9%87%8F%E5%8C%96%E6%8B%A9%E6%97%B6%E7%B3%BB%E5%88%97%EF%BC%881%EF%BC%89%EF%BC%9A%E9%87%91%E8%9E%8D%E5%B7%A5%E7%A8%8B%E8%A7%86%E8%A7%92%E4%B8%8B%E7%9A%84%E6%8A%80%E6%9C%AF%E6%8B%A9%E6%97%B6%E8%89%BA%E6%9C%AF-%E5%88%98%E5%9D%87%E4%BC%9F.pdf",
      },
    ],
    onlyFirm: false,
  },
  {
    id: "b4ffcc91-e55f-4c2f-b938-034a9eb97bb2",
    name: "TSI",
    intro: "TSI",
    paper: [],
    onlyFirm: false,
  },
  {
    id: "e6bd03ee-a6d9-4974-97d3-dc121be9f277",
    name: "WC",
    intro: "WC",
    paper: [],
    onlyFirm: false,
  },
  {
    id: "e055b17c-e0f6-4e03-a713-38a340635533",
    name: "RSI",
    intro: "RSI",
    paper: [],
    onlyFirm: false,
  },
  // {
  //   id: "01a62e76-45e0-4ba2-b149-f5c6ef1d87d7",
  //   name: "PIL动量轮动",
  //   intro: "PIL动量轮动策略",
  //   paper: [],
  //   onlyFirm: true,
  // },
  // {
  //   id: "88295d57-d295-4e2a-93ae-e3c903e10650",
  //   name: "PCT动量轮动",
  //   intro: "PCT动量轮动策略",
  //   paper: [],
  //   onlyFirm: true,
  // },
];

export default {
  strategyList,
};
