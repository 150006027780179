<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="width: 380px" class="mt-2">
      <div class="d-flex justify-space-between align-center">
        <span style="font-size: 13px" class="mr-2">选择策略</span>
        <vxe-select v-model="selectItem" placeholder="选择策略" size="mini">
          <vxe-option
            v-for="item in strategyList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          >
          </vxe-option>
        </vxe-select>
      </div>
      <div>
        <QRS
          v-if="this.selectItem == 'df7ed4af-7376-44da-a70a-c37c65c90687'"
          onlyForm
        ></QRS>
        <LLT
          v-if="this.selectItem == '2eb23e3b-92b3-4a52-9e9f-830d9942c0e7'"
          onlyForm
        ></LLT>
        <GridTrade
          v-if="this.selectItem == '82318ff9-81b8-466c-9674-afcd8dad5933'"
          onlyForm
        ></GridTrade>
        <SupportResistance
          v-if="this.selectItem == 'd4535b8e-b305-48d5-93c6-17bff773e9c8'"
          onlyForm
        ></SupportResistance>
        <TSI
          v-if="this.selectItem == 'b4ffcc91-e55f-4c2f-b938-034a9eb97bb2'"
          onlyForm
        ></TSI>
        <WC
          v-if="this.selectItem == 'e6bd03ee-a6d9-4974-97d3-dc121be9f277'"
          onlyForm
        ></WC>
        <RSI
          v-if="this.selectItem == 'e055b17c-e0f6-4e03-a713-38a340635533'"
          onlyForm
        ></RSI>
      </div>

      <!-- <div class="my-4">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div> -->
    </div>
  </div>
</template>
<script>
import Firm from "@/api/firm.js";
import Bus from "@/components/Bus/bus.js";
import strategyStore from "@/assets/data/strategy.js";

import QRS from "@/components/StockDetail/strategy/QRS.vue";
import LLT from "@/components/StockDetail/strategy/LLT.vue";
import GridTrade from "@/components/StockDetail/strategy/GridTrade.vue";
import SupportResistance from "@/components/StockDetail/strategy/SupportResistance.vue";
import TSI from "@/components/StockDetail/strategy/TSI.vue";
import WC from "@/components/StockDetail/strategy/WC.vue";
import RSI from "@/components/StockDetail/strategy/RSI.vue";

export default {
  name: "FirmAdd",
  components: {
    QRS,
    LLT,
    GridTrade,
    SupportResistance,
    TSI,
    WC,
    RSI,
  },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    Bus.$on("emitStrategyForm", (data) => {
      setTimeout(() => {
        this.cancel();
      }, 500);
    });
  },

  data() {
    return {
      selectItem: "2eb23e3b-92b3-4a52-9e9f-830d9942c0e7",
      strategyList: strategyStore.strategyList,
      submitInfo: {
        name: "",
      },
    };
  },
  methods: {
    submit() {
      //console.log(this.editor.getHtml());;return;
      if (!this.submitInfo.name) {
        this.showToast("请先填写组合名", "warning", 5000);
        return;
      }
      this.showLoading("保存中，请稍候");
      Firm.addFirm(this.submitInfo)
        .then((res) => {
          this.showToast("保存成功", "success", 5000);
          Bus.$emit("refresFirmList", true);

          this.$layer.close(this.layerid);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped="scoped"></style>
