<template>
  <div>
    <div class="my-1 d-flex justify-space-between align-center">
      <span style="font-size: 13px" class="mr-2">选择RSI类型</span>
      <vxe-select v-model="method" placeholder="选择RSI类型" size="mini">
        <vxe-option
          v-for="item in methodList"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        >
        </vxe-option>
      </vxe-select>
    </div>
    <div
      class="my-1 d-flex justify-space-between align-center"
      v-if="!onlyForm"
    >
      <span style="font-size: 13px" class="mr-2">回测起始日期</span>
      <vxe-input
        v-model="start_date"
        placeholder="回测起始日期"
        type="date"
        size="mini"
      ></vxe-input>
    </div>
    <div
      class="my-1 d-flex justify-space-between align-center"
      v-if="!onlyForm"
    >
      <span style="font-size: 13px" class="mr-2">回测结束日期</span>
      <vxe-input
        v-model="end_date"
        placeholder="回测结束日期"
        type="date"
        size="mini"
      ></vxe-input>
    </div>
    <div class="my-1 d-flex justify-space-between align-center">
      <span style="font-size: 13px" class="mr-2">RSI计算窗口</span>
      <vxe-input
        style="width: 80px"
        v-model="n"
        placeholder="RSI计算窗口"
        type="integer"
        min="1"
        max="1000"
        clearable
        size="mini"
      ></vxe-input>
    </div>
    <div
      class="my-1 d-flex justify-space-between align-center"
      v-if="method == 'e055b17c-e0f6-4e03-a713-38a340635533'"
    >
      <span style="font-size: 13px" class="mr-2">信号阈值</span>
      <vxe-input
        style="width: 80px"
        v-model="rsi_low"
        placeholder="信号阈值"
        type="integer"
        min="1"
        max="1000"
        clearable
        size="mini"
      ></vxe-input>
    </div>
    <div
      class="my-1 d-flex justify-space-between align-center"
      v-if="method == 'd926075b-c36b-4be0-9d7e-341a77ed709c'"
    >
      <span style="font-size: 13px" class="mr-2">RSI阈值</span>
      <vxe-input
        style="width: 80px"
        v-model="rsi_entry"
        placeholder="RSI阈值"
        type="integer"
        min="1"
        max="1000"
        clearable
        size="mini"
      ></vxe-input>
    </div>
    <div
      class="my-1 d-flex justify-space-between align-center"
      v-if="method == 'd926075b-c36b-4be0-9d7e-341a77ed709c'"
    >
      <span style="font-size: 13px" class="mr-2">日内k线强度阈值</span>
      <vxe-input
        style="width: 80px"
        v-model="ibs_entry"
        placeholder="日内k线强度阈值"
        type="float"
        min="0"
        max="1000"
        clearable
        size="mini"
      ></vxe-input>
    </div>
    <div
      class="my-1 d-flex justify-space-between align-center"
      v-if="method == '9ba3ae49-c92d-400d-9e2a-3076c3890beb'"
    >
      <span style="font-size: 13px" class="mr-2">最高/最低回看窗口</span>
      <vxe-input
        style="width: 80px"
        v-model="m"
        placeholder="最高/最低回看窗口"
        type="integer"
        min="1"
        max="1000"
        clearable
        size="mini"
      ></vxe-input>
    </div>
    <div
      class="my-1 d-flex justify-space-between align-center"
      v-if="method == '9ba3ae49-c92d-400d-9e2a-3076c3890beb'"
    >
      <span style="font-size: 13px" class="mr-2">RSI过去M日的下轨阈值</span>
      <vxe-input
        style="width: 80px"
        v-model="rsi_lower"
        placeholder="RSI过去M日的下轨阈值"
        type="integer"
        min="1"
        max="1000"
        clearable
        size="mini"
      ></vxe-input>
    </div>
    <div
      class="my-1 d-flex justify-space-between align-center"
      v-if="method == '9ba3ae49-c92d-400d-9e2a-3076c3890beb'"
    >
      <span style="font-size: 13px" class="mr-2">RSI过去M日的上轨阈值</span>
      <vxe-input
        style="width: 80px"
        v-model="rsi_upper"
        placeholder="RSI过去M日的上轨阈值"
        type="integer"
        min="1"
        max="1000"
        clearable
        size="mini"
      ></vxe-input>
    </div>
    <div
      class="my-1 d-flex justify-space-between align-center"
      v-if="method == '9ba3ae49-c92d-400d-9e2a-3076c3890beb'"
    >
      <span style="font-size: 13px" class="mr-2">RSI的多头动量阈值</span>
      <vxe-input
        style="width: 80px"
        v-model="rsi_highest"
        placeholder="RSI的多头动量阈值"
        type="integer"
        min="1"
        max="1000"
        clearable
        size="mini"
      ></vxe-input>
    </div>
    <vxe-button
      class="px-1 py-0"
      status="primary"
      content="开始回测"
      @click="submit()"
      v-if="!onlyForm"
    ></vxe-button>
    <vxe-button
      class="px-2 py-0 mt-2"
      status="primary"
      content="确定"
      @click="emitForm()"
      v-if="onlyForm"
    ></vxe-button>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import strategyStore from "@/assets/data/strategy.js";
import moment from "moment";

export default {
  name: "QRS",
  components: {},
  props: {
    onlyForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      start_date: moment().subtract(2, "years").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      methodList: [
        { id: "e055b17c-e0f6-4e03-a713-38a340635533", name: "RSI经典策略" },
        { id: "d926075b-c36b-4be0-9d7e-341a77ed709c", name: "RSI-IBS策略" },
        { id: "9ba3ae49-c92d-400d-9e2a-3076c3890beb", name: "RSI区域动量策略" },
      ],
      method: "e055b17c-e0f6-4e03-a713-38a340635533",
      n: 2,
      rsi_low: 15,
      rsi_entry: 45,
      ibs_entry: 0.25,

      m: 100,
      rsi_lower: 40,
      rsi_upper: 100,
      rsi_highest: 70,
    };
  },
  computed: {},
  mounted() {},
  watch: {
    method(newValue) {
      switch (newValue) {
        case "e055b17c-e0f6-4e03-a713-38a340635533":
          this.n = 2;
          break;
        case "d926075b-c36b-4be0-9d7e-341a77ed709c":
          this.n = 21;
          break;
        case "9ba3ae49-c92d-400d-9e2a-3076c3890beb":
          this.n = 21;
          break;
        default:
          break;
      }
    },
  },
  methods: {
    emitForm() {
      Bus.$emit("emitStrategyForm", {
        name: this.methodList.find((item) => item.id == this.method).name,
        id: this.method,
        options: this.getOptions(),
      });
    },
    getOptions() {
      let options = {};
      switch (this.method) {
        case "e055b17c-e0f6-4e03-a713-38a340635533":
          options = {
            N: this.n,
            rsi_low: this.rsi_low,
          };
          break;
        case "d926075b-c36b-4be0-9d7e-341a77ed709c":
          options = {
            N: this.n,
            rsi_entry: this.rsi_entry,
            ibs_entry: this.ibs_entry,
          };
          break;
        case "9ba3ae49-c92d-400d-9e2a-3076c3890beb":
          options = {
            N: this.n,
            M: this.m,
            rsi_lower: this.rsi_lower,
            rsi_upper: this.rsi_upper,
            rsi_highest: this.rsi_highest,
          };
          break;
        default:
          break;
      }
      return options;
    },
    getOptionsObj() {
      let optionsObj = {
        name: this.methodList.find((item) => item.id == this.method).name,
        code_type: this.appStore.currentStockDetail.code_type,
        strategyId: this.method,
        stock_code: this.appStore.currentStockDetail.stock_code,
        start_date: this.start_date,
        end_date: this.end_date,
      };

      optionsObj = { ...optionsObj, ...this.getOptions() };
      return optionsObj;
    },
    submit() {
      this.showLoading("正在回测中");

      Stock.useStrategy(this.getOptionsObj())
        .then((res) => {
          this.$commonHelpers.showLog({}, this);
          this.handelLog(res.data.task_id);
        })
        .catch((err) => {
          this.showToast("回测失败，服务器异常！", "danger", 5000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    handelLog(id) {
      let timer = null;
      this.appStore.logData = "";
      const getLog = () => {
        console.log("handelLog:" + id);
        Stock.getLog(id)
          .then((res) => {
            console.log(res);
            this.$commonHelpers.handelLog(res, this);
            if (res.data.status === 0) {
              console.log("正在回测中");
            }

            if (res.data.status === -1) {
              clearInterval(timer);
              this.showToast("回测失败，服务器异常！", "danger", 5000);
            }
            if (res.data.status === 1) {
              clearInterval(timer);
              this.showToast("回测成功！", "success", 5000);
              this.appStore.backTestData = {
                ...res.data.content.data,
                name: this.methodList.find((item) => item.id == this.method)
                  .name,
                id: this.method,
                stock_code: this.appStore.currentStockDetail.stock_code,
                stock_name: this.appStore.currentStockDetail.stock_name,
                start_date: this.start_date,
                end_date: this.end_date,
                options: this.getOptions(),
                optionsObj: this.getOptionsObj(),
              };
              Bus.$emit("showBackTest", true);
              this.appStore.logData += `<span style='color: #2AA49D'>| INFO  |</span>回测已经成功，日志将在三秒后自动关闭！`;
              setTimeout(() => {
                Bus.$emit("closeLogWindow", true);
              }, 3000);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
      getLog();
      timer = setInterval(() => {
        getLog();
      }, 1000);
    },
  },
};
</script>
