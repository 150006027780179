import Request from "@/components/request/js/index.js";

export default {
  //股票详情K线
  //code_type:stock,etf,index
  //code, type, start_date, end_date, has_adj, code_type
  getKLine(data) {
    return Request().post("/api/data/daily", {
      header: {
        contentType: "application/json",
      },
      data: {
        ...data,
      },
    });
  },
  //获取策略列表
  getStrategyList() {
    return Request().get("/api/strategy/list", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //调用策略
  useStrategy(data) {
    return Request().post("/api/strategy/" + data.strategyId, {
      header: {
        contentType: "application/json",
      },
      data: {
        ...data,
      },
    });
  },
  //混合策略
  multiStrategy(data, benchmark = "000300.SH") {
    return Request().post("/api/strategy/multi", {
      header: {
        contentType: "application/json",
      },
      data: {
        data: data,
        benchmark: benchmark,
      },
    });
  },
  //混合策略
  getLog(id) {
    return Request().get("/api/strategy/result/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //搜索股票
  searachStock(search) {
    return Request().get("/api/stocks/search", {
      header: {
        contentType: "application/json",
      },
      data: {
        search: search,
      },
    });
  },
  //收藏策略
  saveStrategy(stock_code, strategy_id, args) {
    return Request().post("/api/user_stocks", {
      header: {
        contentType: "application/json",
      },
      data: {
        stock_code: stock_code,
        strategy_id: strategy_id,
        args: args,
      },
    });
  },
  //删除收藏策略
  deleteStrategy(id) {
    return Request().post("/api/user_stocks/" + id + "/delete", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //获取收藏策略列表
  getSavedStrategy(stock_code) {
    return Request().get("/api/user_stocks/list", {
      header: {
        contentType: "application/json",
      },
      data: {
        stock_code: stock_code,
      },
    });
  },
  //设为默认策略
  setDefaultStrategy(id) {
    return Request().post("/api/user_stocks/" + id + "/default", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
};
