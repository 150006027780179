<template>
  <div>
    <div
      class="my-1 d-flex justify-space-between align-center"
      v-if="!onlyForm"
    >
      <span style="font-size: 13px" class="mr-2">回测起始日期</span>
      <vxe-input
        v-model="start_date"
        placeholder="回测起始日期"
        type="date"
        size="mini"
      ></vxe-input>
    </div>
    <div
      class="my-1 d-flex justify-space-between align-center"
      v-if="!onlyForm"
    >
      <span style="font-size: 13px" class="mr-2">回测结束日期</span>
      <vxe-input
        v-model="end_date"
        placeholder="回测结束日期"
        type="date"
        size="mini"
      ></vxe-input>
    </div>
    <div class="my-1 d-flex justify-space-between align-center">
      <span style="font-size: 13px" class="mr-2">N</span>
      <vxe-input
        style="width: 80px"
        v-model="n"
        placeholder="N"
        type="integer"
        min="1"
        max="1000"
        clearable
        size="mini"
      ></vxe-input>
    </div>
    <div class="my-1 d-flex justify-space-between align-center">
      <span style="font-size: 13px" class="mr-2">M</span>
      <vxe-input
        style="width: 80px"
        v-model="m"
        placeholder="M"
        type="integer"
        min="1"
        max="1000"
        clearable
        size="mini"
      ></vxe-input>
    </div>
    <vxe-button
      class="px-1 py-0"
      status="primary"
      content="开始回测"
      @click="submit()"
      v-if="!onlyForm"
    ></vxe-button>
    <vxe-button
      class="px-2 py-0 mt-2"
      status="primary"
      content="确定"
      @click="emitForm()"
      v-if="onlyForm"
    ></vxe-button>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import strategyStore from "@/assets/data/strategy.js";
import moment from "moment";

export default {
  name: "TSI",
  components: {},
  props: {
    onlyForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      start_date: moment().subtract(2, "years").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      n: 5,
      m: 20,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    emitForm() {
      Bus.$emit("emitStrategyForm", {
        name: "TSI",
        id: "b4ffcc91-e55f-4c2f-b938-034a9eb97bb2",
        options: {
          n: this.n,
          m: this.m,
        },
      });
    },
    getOptionsObj() {
      let optionsObj = {
        name: "TSI",
        code_type: this.appStore.currentStockDetail.code_type,
        strategyId: "b4ffcc91-e55f-4c2f-b938-034a9eb97bb2",
        stock_code: this.appStore.currentStockDetail.stock_code,
        start_date: this.start_date,
        end_date: this.end_date,
        n: this.n,
        m: this.m,
      };
      return optionsObj;
    },
    submit() {
      this.showLoading("正在回测中");
      //strategyId, stock_code, start_date, end_date, method, m, n

      Stock.useStrategy(this.getOptionsObj())
        .then((res) => {
          this.$commonHelpers.showLog({}, this);
          this.handelLog(res.data.task_id);
        })
        .catch((err) => {
          this.showToast("回测失败，服务器异常！", "danger", 5000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    handelLog(id) {
      let timer = null;
      this.appStore.logData = "";
      const getLog = () => {
        console.log("handelLog:" + id);
        Stock.getLog(id)
          .then((res) => {
            console.log(res);
            this.$commonHelpers.handelLog(res, this);
            if (res.data.status === 0) {
              console.log("正在回测中");
            }

            if (res.data.status === -1) {
              clearInterval(timer);
              this.showToast("回测失败，服务器异常！", "danger", 5000);
            }
            if (res.data.status === 1) {
              clearInterval(timer);
              this.showToast("回测成功！", "success", 5000);
              this.appStore.backTestData = {
                ...res.data.content.data,
                name: "TSI",
                id: "b4ffcc91-e55f-4c2f-b938-034a9eb97bb2",
                stock_code: this.appStore.currentStockDetail.stock_code,
                stock_name: this.appStore.currentStockDetail.stock_name,
                start_date: this.start_date,
                end_date: this.end_date,
                options: {
                  n: this.n,
                  m: this.m,
                },
                optionsObj: this.getOptionsObj(),
              };
              Bus.$emit("showBackTest", true);
              this.appStore.logData += `<span style='color: #2AA49D'>| INFO  |</span>回测已经成功，日志将在三秒后自动关闭！`;
              setTimeout(() => {
                Bus.$emit("closeLogWindow", true);
              }, 3000);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
      getLog();
      timer = setInterval(() => {
        getLog();
      }, 1000);
    },
  },
};
</script>
