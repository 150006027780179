import Request from "@/components/request/js/index.js";

export default {
  //获取组合列表
  geFirmList() {
    return Request().get("/api/firm/list", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //删除组合
  deleteFirm(id) {
    return Request().post("/api/firm/" + id + "/delete", {
      header: {
        contentType: "application/json",
      },
      data: {
        id,
      },
    });
  },
  //新建组合
  addFirm(data) {
    return Request().post("/api/firm", {
      header: {
        contentType: "application/json",
      },
      data: data,
    });
  },
  //获取组合详情
  getFirmDetail(id) {
    return Request().get("/api/firm/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //新增成分股
  addStock(data) {
    return Request().post("/api/firm/item", {
      header: {
        contentType: "application/json",
      },
      data: data,
    });
  },
  //删除成分股
  deleteStock(id) {
    return Request().post("/api/firm/item/" + id + "/delete", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
};
